.UserName{
  font-size: 400%;
  margin-bottom: 3%;
  margin-top: 1%;
}

.card-deck{
  text-align: center;
  max-width: 1000px;
  margin: 1%;
  margin-left: 100px;
}

.card{
  margin: 3%;
  max-width: 450px;
  min-width: 250px;
}

.Main{
  margin-left: 5%;
}

.View{
  border: 5px solid black;
  margin-left: 8%;
  width: 80%;
}

.checked{
  margin-top: 2%;
  color: orange;
}

.star{
  margin-top: 1%;
  margin-left: 80%;
}

.mb-3{
  max-width: 60%;
  margin-top: 50px;
  margin-left: 20%;
}

.form-control{
  margin-top: 40px;
}

.input-group-text{
  margin-top: 40px;
}

.welcome{
  text-align: center;
}

.submit{
  margin-left: 46%;
  width: 8%;
  color: black;
  margin-top: 6%;
  background-color: rgb(250, 255, 200);
  border-radius: 20px;
}
.banner{
  width: 50%;
  max-height: 20%;
}