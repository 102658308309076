.login {
    position: relative;
    margin: auto;
    width: 1000px;
    height: 700px;
    background-color: gray;
    background-image: url("https://en.wikipedia.org/wiki/Cultural_landscape#/media/File:Neckertal_20150527-6384.jpg");
}

.title {
    text-align: center;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    font-size: 50px;
}

.buttons {
    background-color: darkgreen;
    margin: auto;
    border-radius: 10px;
    border: black;
    height: 200px;
    opacity: 95%;
}

.register {
    margin: auto;
    margin-left: 395px;
    margin-top: 30px;
    background-color: lightseagreen;
    border-radius: 5px;

}

.logout {
    background-color: lightseagreen;
    border-radius: 5px;
}