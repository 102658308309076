.container {
    position: relative;
    max-width: 970px;
}

.container img {
    max-width: 100%;
    max-height: 700px;
}

.invisible {
    display: none;
}
.floating {
    display: block;
    z-index: 100;
    background-color: white;
    border-radius: 15px;
    border: 1px solid black;
    padding: 15px;
}

.floating input {
    width: 900px;
}

.active {
    color: red;
    font-weight: bold;
}

.regular {
    color: black;
}